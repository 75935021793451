body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.activityBox {
	position: relative;
	margin: 0px 2px 0px 2px;
    padding: 1px 2px 1px 2px;
    border: 1px solid #dddddd;
	cursor: pointer;
}

.addActivityBox {
	color: #ccc;
	text-align: center;
	font-weight: 300;
	font-size: 150%;
    margin: 3px 1px 0px 1px;
	height: 50px;
    border: 1px solid #ccc;
	cursor: pointer;
	opacity: 0;
}

.activityBoxDim {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(255, 255, 255, .75);
}

.activityBox:hover {
   border-color: #888888;
}

.durationText {
	font-weight: 800;
	font-size: 120%;
}

.titleText {
	font-weight: bold;
	font-size: 100%;
}

.descriptionText {
	font-size: 75%;
	line-height: 140%;
	display: block;
}

.outer {
   opacity: 0;
   float: right;
}
    
.outer:hover {
    opacity: 1;
}

.dropdown-menu.show {
	display: block;
	min-width: 60px;
}

.addActivityBox:hover {
    opacity: 1;
}

.dropbox-margins {
   margin: 0px 2px 0px 2px;
}

.dropbox-height {
   height: 6px;
}

.custom-dialog {
	max-width: 650px
	!important; top:0%;
}

